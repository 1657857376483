import React, { useEffect, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from "swiper"
import FadeOnScroll from "./FadeoNscroll"
// Import Swiper styles
import 'swiper/css'
import "swiper/css/navigation"

const Slider = () => {

  const [gallery, setGallery] = useState([])
  useEffect(() => {
    fetch(`https://admin.hwlsurf.pt/wp-json/wp/v2/pages?slug=homepage&acf_format=standard`)
      .then(response => response.json())
      .then(data => {
        setGallery(data[0].acf.galeria)
      })
      .catch(error => console.error(error))
  }, []);

  return (
    <FadeOnScroll>
      <div className="pb-24"
      // style={{ backgroundImage: `linear-gradient(to bottom,white 0%,white 50%,#2D61F1 50%,#2D61F1 100%)`, }}
      >
        <Swiper
          navigation={true}
          modules={[Navigation]}
          slidesPerView={1.2}
          spaceBetween={30}
          breakpoints={{
              640: {
                  slidesPerView: 4.5,
                  spaceBetween: 30,
              },
          }}
        >
          {gallery.map(image => (
            <SwiperSlide key={image.ID}><img loading="lazy" src={image.url} alt={image.alt} /></SwiperSlide>
          ))}
        </Swiper>
      </div>
    </FadeOnScroll>
  );
};

export default Slider;
