import React, { useEffect, useState } from "react"

const Hero = () => {

    const [banner, setBanner] = useState(null)
    useEffect(() => {
      fetch(`https://admin.hwlsurf.pt/wp-json/wp/v2/pages?slug=homepage&acf_format=standard`)
        .then(response => response.json())
        .then(data => {
          setBanner(data[0].acf.hero)
        })
        .catch(error => console.error(error))
    }, []);

    return (
        <div className="relative bg-blue h-[80vh] overflow-hidden">
            <div className="absolute left-10 top-10 z-20">
                <img src={require('../images/s.png')} alt="HWL Surf" loading="lazy"/>
            </div>
            <div className="absolute right-10 top-10 z-20">
                <img src={require('../images/u.png')} alt="HWL Surf" loading="lazy" />
            </div>
            <img
                className={`absolute top-0 left-0 w-full h-full z-10 object-cover object-center transition-opacity ease-in ${banner ? 'opacity-100' : 'opacity-0'}`}
                src={banner}
                loading="lazy"
                alt="HWL Surf Banner"
            />
            <div className="absolute left-10 bottom-10 z-20">
                <img src={require('../images/r.png')} alt="HWL Surf" loading="lazy" />
            </div>
            <div className="absolute right-10 bottom-10 z-20">
                <img src={require('../images/f.png')} alt="HWL Surf" loading="lazy" />
            </div>
        </div>
    );
};

export default Hero;
