import React, { useEffect, useState } from "react"
import FadeOnScroll from "./FadeoNscroll"

const About = () => {

    const [titulo, setTitulo] = useState('')
    const [tituloBold, setTituloBold] = useState('')
    const [intro, setIntro] = useState('')
    useEffect(() => {
      fetch(`https://admin.hwlsurf.pt/wp-json/wp/v2/pages?slug=homepage&acf_format=standard`)
        .then(response => response.json())
        .then(data => {
          setTitulo(data[0].acf.titulo_about)
          setTituloBold(data[0].acf.titulo_bold_about)
          setIntro(data[0].acf.sobre)
        })
        .catch(error => console.error(error))
    }, []);

    return (
        <FadeOnScroll>
            <div className="relative -mt-44">
                <img className="h-[100vh] bg-center object-cover" loading="lazy" src={require('../images/section2.png')} alt="HWL Surf" />
                <div className="container absolute px-4 m-auto left-0 right-0 top-36 md:top-48">
                    <h2 className="uppercase text-white font-galanoRegular text-center text-xl md:text-4xl break-words">{titulo} <span className="font-galanoHeavy tracking-[.15em]">{tituloBold}</span></h2>
                    <p className="text-white text-center pt-14 pb-12 mx-auto max-w-4xl">{intro}</p>
                    <div className="max-w-4xl mx-auto">
                        <img className="w-14 mx-auto pt-2" loading="lazy" src={require('../images/logo.png')} alt="HWL Surf" />
                    </div>
                </div>
            </div>
        </FadeOnScroll>
    );
};

export default About;
