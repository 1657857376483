import React, { useEffect, useState } from "react"
import FadeOnScroll from "./FadeoNscroll"

const Emles = () => {
    const [intro, setIntro] = useState('')
    useEffect(() => {
      fetch(`https://admin.hwlsurf.pt/wp-json/wp/v2/pages?slug=homepage&acf_format=standard`)
        .then(response => response.json())
        .then(data => {
          setIntro(data[0].acf.introducao_emles)
        })
        .catch(error => console.error(error))
    }, []);
    return (
      <FadeOnScroll>
        <div className="relative mt-48 md:mt-12">
          <img loading="lazy" src={require('../images/section3.png')} alt="HWL Surf" />
          <div className="container max-w-4xl absolute bottom-3 md:bottom-20 m-auto left-0 right-0">
            <img className="w-44 md:w-72 mx-auto" src={require('../images/emles.png')} alt="HWL Surf" />
            <p className="text-white text-center pt-8 pb-12 px-4 md:px-0">{intro}</p>
          </div>
        </div>
      </FadeOnScroll>
    );
};

export default Emles;
