import React, { useEffect, useState } from "react"
import FadeOnScroll from "./FadeoNscroll"

const Team = () => {

    const [team, setTeam] = useState([])
    useEffect(() => {
      fetch(`https://admin.hwlsurf.pt/wp-json/wp/v2/pages?slug=homepage&acf_format=standard`)
        .then(response => response.json())
        .then(data => {
          setTeam(data[0].acf.equipa)
        })
        .catch(error => console.error(error))
    }, [])

    return (
      <FadeOnScroll>
        <div className="mt-16 md:mt-28">
          <h2 className="uppercase text-white font-galanoRegular text-center text-xl md:text-4xl break-words"><span className="font-galanoHeavy tracking-[.55em]">HWL</span> SURF TEAM</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-6 max-w-lg mx-auto pt-14">
              {team.map(member => (
                <div className="px-4 md:px-0">
                    <a rel="noreferrer" href={member.url} target="_blank">
                        <img loading="lazy" src={member.foto} alt={member.nome} />
                        <div className="bg-white p-5">
                            <h2 className="text-blue font-galanoBlack tracking-[.55em]">{member.nome}</h2>
                            <p className="text-blue tracking-[.15em] pt-2 text-sm font-galanoMedium">{member.cargo}</p>
                        </div>
                    </a>
                </div>
              ))}
          </div>
        </div>
      </FadeOnScroll>
    );
};

export default Team;
