import React, { useEffect, useState } from "react"
import FadeOnScroll from "./FadeoNscroll"

const Intro = () => {

    const [titulo, setTitulo] = useState('')
    const [tituloBold, setTituloBold] = useState('')
    const [intro, setIntro] = useState('')
    useEffect(() => {
      fetch(`https://admin.hwlsurf.pt/wp-json/wp/v2/pages?slug=homepage&acf_format=standard`)
        .then(response => response.json())
        .then(data => {
          setTitulo(data[0].acf.titulo)
          setTituloBold(data[0].acf.titulo_bold)
          setIntro(data[0].acf.introducao)
        })
        .catch(error => console.error(error))
    }, []);

    return (
        <FadeOnScroll>
            <div className="container mx-auto px-4 py-16 md:py-24">
                <h1 className="uppercase text-blue font-galanoRegular text-center text-xl md:text-4xl tracking-[.15em] break-words">{titulo} <span className="font-galanoHeavy tracking-[.45em]">{tituloBold}</span></h1>
                <p className="text-blue text-center py-14 mx-auto max-w-4xl">{intro}</p>
                {/*<div className="flex justify-center">
                    <Btn
                        url={"#form"}
                        text="GET IN TOUCH"
                    />
                </div>*/}
            </div>
        </FadeOnScroll>
    );
};

export default Intro;
