import React from "react"
import { useInView } from "react-intersection-observer"

const FadeOnScroll = ({ children }) => {
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
  });
  return (
    <div
      ref={ref}
      className={`transition-opacity duration-1000 ${
        inView ? "opacity-100" : "opacity-0"
      }`}
    >
      {children}
    </div>
  )
}

export default FadeOnScroll
