import React, { useEffect, useState } from "react"
import Team from './team'
import Emles from './emles'

const SustainableAndTeam = () => {

    const [titulo, setTitulo] = useState('')
    const [tituloBold, setTituloBold] = useState('')
    const [intro, setIntro] = useState('')
    const [image, setImage] = useState('')
    useEffect(() => {
      fetch(`https://admin.hwlsurf.pt/wp-json/wp/v2/pages?slug=homepage&acf_format=standard`)
        .then(response => response.json())
        .then(data => {
          setTitulo(data[0].acf.titulo_sustainable)
          setTituloBold(data[0].acf.titulo_bold_sustainable)
          setIntro(data[0].acf.texto_sustainable)
          setImage(data[0].acf.img_sustainable)
        })
        .catch(error => console.error(error))
    }, []);

    return (
        <div className="bg-blue">
            <div className="relative container mx-auto">
                <img className="relative -mt-32 h-screen object-cover md:h-full md:object-none blur-[2px] md:blur-[0px]" loading="lazy" src={image} alt="HWL Surf" />
                <div className="container max-w-4xl absolute top-16 md:top-28 m-auto left-0 right-0">
                    <div className="bg-white h-1 max-w-xs md:max-w-sm mx-auto mb-8 md:mb-16"></div>
                    <h2 className="uppercase text-white font-galanoRegular text-center text-xl md:text-4xl break-words"><span className="font-galanoHeavy tracking-[.15em]">{tituloBold}</span> {titulo}.</h2>
                    <p className="text-white text-center pt-8 md:pt-14 pb-12 px-4">{intro}</p>
                </div>
                <Team />
            </div>
            <Emles />
        </div>
    );
};

export default SustainableAndTeam;
