import Hero from "./components/hero";
import Intro from "./components/intro"
import Slider from "./components/slider"
import About from "./components/about"
import SustainableAndTeam from './components/sustainable_team'
//import Form from './components/form'

function App() {
  return (
    <div className="surfApp">
      <Hero />
      <Intro />
      <Slider />
      <About />
      <SustainableAndTeam />
      {/*<Form />*/}
    </div>
  );
}

export default App;
